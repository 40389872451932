import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { Card, Row, Col } from 'react-bootstrap';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import { Link } from 'react-router-dom';
import { BotDashboard as textLang } from 'staticData/languages';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const NoPortfolio = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);

  const [selectPortfolio, setSelectPortfolio] = useState();

  useEffect(() => {
    const initData = async () => {
      try {
        // banner
        const bannerRandomID = Math.floor(Math.random() * 3);
        setSelectPortfolio(portfolioList[bannerRandomID]);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (Object.keys(userInfos).length > 0 && !selectPortfolio) initData();
  }, [userInfos]);

  return (
    <>
      <Row className="my-3 pointerCursor">
        <Col>
          <Card
            style={{
              backgroundImage: selectPortfolio ? selectPortfolio.bgStyle : ''
            }}
          >
            <Card.Body
              as={Link}
              to="/invest?exchange=binance&type=portfolio"
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <Row>
                <Col sm={5}>
                  {selectPortfolio && (
                    <LottieBotLazy
                      type={selectPortfolio.id}
                      loop={true}
                      style={{ width: '100%' }}
                    />
                  )}
                </Col>
                <Col md={6} as={Flex} className="align-items-center">
                  <div>
                    <h4>{textLang.noBotTitle[lang]}</h4>
                    <p>{textLang.noBotText[lang]}</p>
                    <IconButton
                      variant="primary"
                      className="me-2 mb-1 "
                      icon="rocket"
                      iconAlign="left"
                      transform="shrink-3"
                    >
                      {textLang.noBotButton[lang]}
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NoPortfolio;
