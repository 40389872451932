import React, { useState, useEffect, useContext } from 'react';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import AppContext from 'context/Context';
import { ToDoList as textLang } from 'staticData/languages';
import 'assets/scss/custom/realCard.scss';
import FirstAccessModal from './modals/firstAccess';
import NewRealeseModal from './modals/newRelease';
import MessagePopUp from 'pages/common/modals/messagePopUp';
import BotDashboard from './BotDashboard';
import { useHistory } from 'react-router-dom';
import Onboard from './Onboard';
import { completionStatus } from 'services/onboard';
// import Banner from './Banner';

const index = ({ getInfo }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [firstAccessModalShow, setFirstAccessModalShow] = useState(false);
  const [newReleaseModalShow, setNewReleaseModalShow] = useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [popUps, setPopUps] = useState({});
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const [doKYCShow, setDoKYCShow] = useState(false);
  const [addKeysShow, setAddKeysShow] = useState(false);
  const routerHistory = useHistory();
  const popUpKey = 'dashboard';

  const getPopUps = async () => {
    let popUpsResponse = await endpointInterface(
      lang,
      'backend',
      'getPopUps',
      'get',
      true
    );
    if (popUpsResponse.validResponse) {
      setPopUps(popUpsResponse.data);
      if (Object.keys(popUpsResponse.data).includes(popUpKey)) {
        setMessageModalShow(true);
      }
    }
  };

  const todoList = [
    {
      id: 'emailConfirmed',
      task: textLang.mailTask[lang],
      description: textLang.mailDescr[lang],
      color: 'primary',
      time: '1 min'
    },
    {
      id: 'kyc',
      task: textLang.kycTask[lang],
      description: textLang.kycDescr[lang],
      customColor: '#ffc107',
      time: '2 min'
    },
    {
      id: 'exchange',
      task: textLang.exchangeTask[lang],
      description: textLang.exchangeDescr[lang],
      color: 'success',
      time: '1 min'
    },
    // {
    //   id: 'proTier',
    //   task: textLang.proTierTask[lang],
    //   description: textLang.proTierDescr[lang],
    //   color: 'warning',
    //   time: '5 min'
    // },
    {
      id: 'realBotStarted',
      task:
        textLang.lastStepTask[lang] +
        (userInfos.trial_allowed
          ? textLang.lastStepTaskTrialAllowed[lang]
          : textLang.lastStepTaskTrialNOTAllowed[lang]),
      description:
        textLang.lastStepDescr[lang] +
        (userInfos.trial_allowed
          ? textLang.lastStepDescrTrialAllowed[lang]
          : '.'),
      color: 'warning',
      time: '5 min'
    }
  ];

  const progressMapAction = {
    kyc: () => setDoKYCShow(true),
    exchange: () => setAddKeysShow(true),
    emailConfirmed: () => setConfirmMailShow(true),
    realBotStarted: () => routerHistory.push('/invest')
  };

  const completionInfo = completionStatus(userInfos, progressMapAction);

  useEffect(() => {
    const fetchData = async () => {
      try {
        getPopUps();
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (Object.keys(userInfos).length > 0 && !loaded) fetchData();
  }, [userInfos]);

  return (
    <>
      <MessagePopUp
        messageModalShow={messageModalShow}
        setMessageModalShow={setMessageModalShow}
        data={
          Object.keys(popUps).includes(popUpKey) ? popUps[popUpKey].content : {}
        }
        popUpId={
          Object.keys(popUps).includes(popUpKey) ? popUps[popUpKey].id : 0
        }
      />
      <FirstAccessModal
        firstAccessModalShow={firstAccessModalShow}
        setFirstAccessModalShow={setFirstAccessModalShow}
      />
      <NewRealeseModal
        newReleaseModalShow={newReleaseModalShow}
        setNewReleaseModalShow={setNewReleaseModalShow}
      />
      {/* {loaded && <Banner />} */}
      {!completionInfo.completed ? (
        <Onboard
          getInfo={getInfo}
          completionInfo={completionInfo}
          todoList={todoList}
          ConfirmMailShow={ConfirmMailShow}
          setConfirmMailShow={setConfirmMailShow}
          doKYCShow={doKYCShow}
          setDoKYCShow={setDoKYCShow}
          addKeysShow={addKeysShow}
          setAddKeysShow={setAddKeysShow}
        />
      ) : (
        <BotDashboard />
      )}
    </>
  );
};

export default index;
